import React from "react";

// markup
const IndexPage = () => {
  return (
    <div>Yes here is the contevnt yooooooo</div>
  )
}

export default IndexPage
